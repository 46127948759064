import { Component, OnInit } from '@angular/core';
import { IrisLocalStorageService } from '@iris/common/services/utils/iris-local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'language-select',
  templateUrl: './language-select.component.html',
})
export class LanguageSelectComponent implements OnInit {
  userLang = 'en';

  constructor(
    private readonly translate: TranslateService,
    private readonly localStorageService: IrisLocalStorageService,
  ) { }

  ngOnInit(): void {
    if (['en', 'de'].includes(this.translate.currentLang)) {
      this.userLang = this.translate.currentLang;
    }
  }

  changeLanguage(language: string) {
    if (this.translate.currentLang !== language) {
      this.localStorageService.setItem('defaultAuthLanguage', language);
      document.location.reload();
    }
  }
}
