/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { API_ENV } from '@iris/common/tokens';
import { AppModule } from 'app/app.module';
import { createEnvironment } from 'create-environment.util';
import { environment } from '@environments/environment';
import { UNLEASH_CONFIG } from '@karelics/angular-unleash-proxy-client';
import { IConfig } from 'unleash-proxy-client';

if (environment.production) {
  enableProdMode();
}

fetch(environment.configURL)
  .then(response => response.json())
  .then(createEnvironment)
  .then((env: IrisEnv) => platformBrowserDynamic([{
    provide: API_ENV,
    useValue: env,
  }, {
    provide: UNLEASH_CONFIG,
    useValue: {
      url: env.unleashProxyURL,
      clientKey: env.unleashProxyKey,
      appName: env.instanceName,
      disableRefresh: true,
      disableMetrics: true,
    } as IConfig,
  }]).bootstrapModule(AppModule))
  .catch(err => console.error(err));

// define Strakit UI theme
const root = document.querySelector('html');
const body = document.querySelector('body');
root.classList.add('sk-theme-light');
// set specific class for print mode
const urlParams = new URLSearchParams(window.location.search);
const printParam = urlParams.get('print');
if (printParam) {
  body.classList.add('print-mode');
}

// polyfill for printing
window['irisPrintingStack'] = {
  isEmpty: () => true,
};
