<div class="d-flex btn-group" role="group" [ngClass]="{'view-tab': view === 'tab'}">
  @for (option of options; track option.title) {
    <button
      type="button"
      class="btn"
      [disabled]="disabled"
      (click)="onSelect(option.value)"
      [ngClass]="getOptionClass(option)"
      [matTooltip]="option.tooltip"
    >
      @if (option.icon) {
        <i class="fa-fw" [ngClass]="option.icon"></i>
        @if (!hideTitle) {
          <span> {{ option.title }} </span>
        }
      } @else if (!hideTitle) {
        {{ option.title }}
      }
    </button>
  }
</div>
