import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IrisQueryParamsBuilder } from '@iris/api-query';
import { LoginResult } from './login-result';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { catchError } from 'rxjs/operators';

interface UsersCheckResult {
  enabled: boolean;
  accountNonExpired: boolean;
  singleSignOnActive: boolean;
}

@Injectable({ providedIn: 'root' })
export class IrisAuthService {
  get instanceId(): string {
    return this.envService.env.instanceId;
  }

  get msal(): { clientId: string; tenantId: string; scope: string } {
    return this.envService.env.msal;
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly envService: IrisEnvironmentService,
  ) { }

  loginUsername(username: string, password: string): Observable<LoginResult> {
    return this.httpClient.post<LoginResult>(`/idp/${this.instanceId}/iris/login/users`, { username, password });
  }

  impersonateUserById(userId: number): Observable<LoginResult> {
    return this.httpClient.post<LoginResult>(`/idp/${this.instanceId}/iris/login/users/${userId}`, {});
  }

  loginAzure(azureAccessToken: string): Observable<LoginResult> {
    const headers = {
      'Authorization': `Bearer ${azureAccessToken}`,
    };
    return this.httpClient.get<LoginResult>(`/idp/${this.instanceId}/azure/oauth2/authorize/v2`, { observe: 'body', headers });
  }

  refreshToken(refreshToken: string): Observable<LoginResult> {
    const queryParams = new IrisQueryParamsBuilder().urlParam('refreshToken', refreshToken).toStructure();
    return this.httpClient.get<LoginResult>(`/idp/${this.instanceId}/iris/tokens/refresh`, { params: queryParams.toObject() });
  }

  updateNodeToken(accessToken?: string): Observable<void> {
    return this.httpClient.post<void>(`${location.origin}/api/login-jwt`, { token: accessToken }).pipe(
      catchError(() => of(null)),
    );
  }

  checkSSO(username: string): Observable<UsersCheckResult> {
    return this.httpClient.post<UsersCheckResult>(
      '/security/users/check',
      { username },
    );
  }

  sendResetLink(email: string): Observable<unknown> {
    return this.httpClient.post(
      `/security/users/send-reset-email?email=${email}`,
      {},
    );
  }

  resetPassword(id: string, hash: string, newPassword: string, newPasswordConfirm: string): Observable<unknown> {
    return this.httpClient.post(
      `/security/users/reset-pwd?id=${id}&hash=${hash}`,
      { newPassword, newPasswordConfirm },
    );
  }

  getMsalRedirectUri(): string {
    return `${location.origin}/ui/ui2/login/sso`;
  }

  logout(): Observable<void> {
    return this.httpClient.post<void>('/security/users/logout', {});
  }
}
