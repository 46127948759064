import { ApiUrl, IrisApiClient, IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { Observable, ReplaySubject } from 'rxjs';
import { IrisDocumentTemplateI } from '@iris/common/models/IrisDocumentTemplate';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { DfAbstractRule, DfRule } from '@iris/common/modules/dynamic-forms/df-rules/dto';
import { splitPropertyPath } from '@iris/common/modules/dynamic-forms/utils/df-path.utils';
import { IrisPage } from '@iris/common/models/page';
import { AgentUsage } from '@iris/common/models/IrisProgramAgent';

export const DEFAULT_ICON_CLASS = 'fab fa-wpforms';
export const DEFAULT_ICON_CHAR_CODE = 'F298';

export const ONLY_FIELDS_FOR_DOCUMENT_TEMPLATE_PAGE = ['count', 'elements', 'id', 'name', 'productId', 'updatedOn', 'code'];

@Injectable({
  providedIn: 'root',
})
@ApiUrl('{iris@formsUrlPart}/documents/document-templates')
export class IrisDocumentTemplateService extends IrisApiClient<IrisDocumentTemplateI> {
  private readonly itemCache = new Map<number, Observable<IrisDocumentTemplateI>>();

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getByIdCached(documentTemplateId: number, force = false): Observable<IrisDocumentTemplateI> {
    if (!this.itemCache.has(documentTemplateId) || force) {
      this.itemCache.set(documentTemplateId, this.getById(documentTemplateId).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        }),
      ));
    }
    return this.itemCache.get(documentTemplateId);
  }

  getDocumentTemplateLinkById(documentTemplateId: string): string {
    return `configuration/dynamic-forms/forms/${documentTemplateId}`;
  }

  @ApiUrl('~/page')
  getDocumentTemplates(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisPage<IrisDocumentTemplateI>> {
    if (!params.onlyFields?.length) {
      params = new IrisQueryParamsBuilder(params).onlyFields(ONLY_FIELDS_FOR_DOCUMENT_TEMPLATE_PAGE).toStructure();
    }
    return this.httpClient.get<IrisPage<IrisDocumentTemplateI>>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('~/project/{projectId}/page')
  getDocumentTemplatesByProject(projectId: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisPage<IrisDocumentTemplateI>> {
    return this.httpClient.get<IrisPage<IrisDocumentTemplateI>>(this.url({ projectId }), { params: params.toObject() });
  }

  @ApiUrl('~/{documentTemplateId}')
  save(documentTemplate: IrisDocumentTemplateI): Observable<IrisDocumentTemplateI> {
    return this.httpClient.post<IrisDocumentTemplateI>(this.url({ documentTemplateId: documentTemplate.id }), documentTemplate);
  }

  @ApiUrl('~/{documentTemplateId}')
  deleteDocumentTemplate(documentTemplateId: number): Observable<IrisDocumentTemplateI> {
    return this.httpClient.delete<IrisDocumentTemplateI>(this.url({ documentTemplateId }), { });
  }

  @ApiUrl('~/{documentTemplateId}')
  getById(documentTemplateId: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisDocumentTemplateI> {
    return this.getCore({ documentTemplateId }, params);
  }

  @ApiUrl('~/codes/{documentTemplateCode}')
  getByCode(documentTemplateCode: string, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisDocumentTemplateI> {
    return this.getCore({ documentTemplateCode }, params);
  }

  @ApiUrl('~/{documentTemplateId}')
  update(documentTemplate: Partial<IrisDocumentTemplateI>): Observable<IrisDocumentTemplateI> {
    return this.httpClient.post<IrisDocumentTemplateI>(
      this.url({ documentTemplateId: documentTemplate.id }), documentTemplate,
    );
  }

  getAll(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisDocumentTemplateI[]> {
    return this.queryCore({}, params);
  }

  getAllTemplatePartial<T>(params: IrisQueryParams = new IrisQueryParams()): Observable<T[]> {
    return this.queryCore({}, params) as unknown as Observable<T[]>;
  }

  @ApiUrl('{iris@formsUrlPart}/documents/projects/{projectId}/document-templates')
  getByProject(projectId: number, params: IrisQueryParams = new IrisQueryParams()): Observable<Partial<IrisDocumentTemplateI[]>> {
    return this.httpClient.get<IrisDocumentTemplateI[]>(this.url({ projectId }), { params: params.toObject() });
  }

  @ApiUrl('{iris@formsUrlPart}/documents/workflow/{workflowId}/document-templates')
  getByWorkflow(workflowId: number, params: IrisQueryParams = new IrisQueryParams()): Observable<Record<number, string>> {
    return this.httpClient.get<Record<number, string>>(this.url({ workflowId }), { params: params.toObject() });
  }

  @ApiUrl('~/{documentTemplateId}/email-templates')
  getEmailConfigs(documentTemplateId: number): Observable<EmailConfigurationI[]> {
    return this.httpClient.get<EmailConfigurationI[]>(this.url({ documentTemplateId }));
  }

  @ApiUrl('~/{documentTemplateId}/email-templates/active/{projectId}')
  getEmailConfigsByProjectId(documentTemplateId: number, projectId: number): Observable<EmailConfigurationI[]> {
    if (!projectId) {
      return this.getEmailConfigs(documentTemplateId);
    }

    return this.httpClient.get<EmailConfigurationI[]>(this.url({ documentTemplateId, projectId }));
  }

  @ApiUrl('~/agents/{agentId}')
  getAgentUsages(agentId: number): Observable<AgentUsage[]> {
    return this.httpClient.get<AgentUsage[]>(this.url({ agentId }));
  }

  @ApiUrl('~/{documentTemplateId}/workflow-remove')
  removeWorkflow(documentTemplateId: number): Observable<void> {
    return this.httpClient.post<void>(this.url({ documentTemplateId }), {});
  }

  @ApiUrl('~/{documentTemplateId}/workflow-states-mapping-required')
  workflowStatesMappingRequired(documentTemplateId: number): Observable<{ mappingRequired: boolean }> {
    return this.httpClient.get<{ mappingRequired: boolean }>(this.url({ documentTemplateId }));
  }

  @ApiUrl('~/product/{productId}')
  getTemplatesByProductId(productId: string): Observable<IrisDocumentTemplateI[]> {
    return this.httpClient.get<IrisDocumentTemplateI[]>(this.url({ productId }));
  }

  postProcessing(documentTemplate: IrisDocumentTemplateI): IrisDocumentTemplateI {
    fixPropertyPath(documentTemplate);
    return documentTemplate;
  }
}

function fixPropertyPath(documentTemplate: IrisDocumentTemplateI): void {
  const allRules: DfRule[] = [
    ...(documentTemplate.rules ?? []),
    ...(documentTemplate.formInfo?.rules ?? []),
  ];

  allRules.forEach(rule => {
    const items: DfAbstractRule[] = [
      ...(rule.conditions ?? []),
      ...(rule.actions ?? []),
    ];

    items.forEach(item => {
      if (item.propertyMeta) {
        item.propertyMeta.path = splitPropertyPath(item.property).join('.');
      }

      if (item.valuePropertyMeta) {
        item.valuePropertyMeta.path = splitPropertyPath(item.valueProperty).join('.');
      }
    });
  });
}
