import { IrisAlert, IrisAlertType } from '@iris/common/modules/alert/model/AlertModel';
import { IrisNotificationPriorityEnum } from '../models/IrisNotificationPriorityEnum';

export function alertNotification(notification: IrisNotificationI): Pick<IrisAlert, 'type' | 'message'>  {
  let irisAlertType: IrisAlertType;

  switch (notification?.notificationPriority?.id) {
    case IrisNotificationPriorityEnum.Normal:
      irisAlertType = IrisAlertType.Success;
      break;
    case IrisNotificationPriorityEnum.Critical:
    case IrisNotificationPriorityEnum.Blocker:
      irisAlertType = IrisAlertType.Error;
      break;
    default:
      irisAlertType = IrisAlertType.Log;
  }

  return {
    type: irisAlertType,
    message: notification?.message,
  };

}
