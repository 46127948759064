import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { debounceTime, map, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@natlex/ng-select';
import { TranslateService } from '@ngx-translate/core';
import isNil from 'lodash/isNil';
import { PageTitleService } from '@iris/common/services/page-name/page-title.service';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';

@Component({
  selector: 'iris-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './dynamic-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'header-menu-container',
        xPosition: 'before',
        overlapTrigger: false,
      },
    },
  ],
})
export class IrisHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('projectSelector', { static: true }) projectSelector: NgSelectComponent;

  projectControl = new FormControl();
  searchIsOpened = false;

  projectSelectorPlaceholder = `${this.translateService.instant('label.Loading')}...`;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    readonly pageTitleService: PageTitleService,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly translateService: TranslateService,
  ) {
    this.globalSandbox.currentProjectId$.pipe(
      tap(projectId => {
        if (isNil(projectId)) {
          this.projectSelectorPlaceholder = this.translateService.instant('label.AllProjects');
        }
        this.projectControl.setValue(projectId, { emitEvent: false });
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnInit(): void {
    combineLatest([
      this.projectControl.valueChanges,
      this.projectSelector.blurEvent.pipe(startWith(null as Event)),
    ]).pipe(
      map(([projectId, _]) => projectId),
      withLatestFrom(this.globalSandbox.currentProjectId$),
      debounceTime(500),
      tap(([projectId, prevId]) => {
        if (prevId === projectId || (!projectId && this.projectSelector.isOpen)) {
          return;
        }
        this.globalSandbox.changeCurrentProjectId(projectId, true);
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  toggleSearchExpansion(isOpen: boolean): void {
    this.searchIsOpened = isOpen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
