import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';

export function getAzureScopes(authService: IrisAuthService): string[] {
  return [
    `api://${ authService.msal.clientId }/${ authService.msal.scope }`,
    'Files.ReadWrite',
    'Files.ReadWrite.All',
    'Group.ReadWrite.All',
    'GroupMember.Read.All',
    'Mail.ReadWrite.Shared',
    'Mail.Send.Shared',
    'Sites.ReadWrite.All',
    'User.ReadBasic.All',
    'email',
    'offline_access',
    'openid',
    'profile',
    'user.read',
  ];
}

export function MSALInstanceFactory(authService: IrisAuthService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: authService.msal.clientId,
      authority: `https://login.microsoftonline.com/${authService.msal.tenantId}`,
      redirectUri: authService.getMsalRedirectUri(),
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowPlatformBroker: false,
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(authService: IrisAuthService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set(authService.getMsalRedirectUri(), getAzureScopes(authService));

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(authService: IrisAuthService): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: getAzureScopes(authService),
    },
    loginFailedRoute: '/ui/ui2/profile',
  };
}
