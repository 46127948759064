import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { WebsocketService } from '../websockets/websocket.service';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisNotificationsService } from '../notifications.service';
import isNil from 'lodash/isNil';
import { IrisAlertService } from '../../modules/alert/service/alert.service';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { alertNotification } from '@iris/common/utils/alert.utils';

export interface AllNotificationResult {
  moreAvailable: boolean;
  notifications: IrisNotificationI[];
}

@Injectable({ providedIn: 'root' })
export class IrisCurrentUserNotificationStore implements OnDestroy {

  private readonly _destroyedSubject = new Subject<void>();
  private readonly allUserNotificationsSubject = new BehaviorSubject<AllNotificationResult>({ moreAvailable: false, notifications: [] });
  private readonly currentUserNotificationsSubject = new BehaviorSubject<IrisNotificationI[]>([]);
  private unlockRequestedNotifications$: Observable<unknown>;

  allNotifications$: Observable<AllNotificationResult> = this.allUserNotificationsSubject.asObservable();
  unreadNotifications$: Observable<IrisNotificationI[]> = this.currentUserNotificationsSubject.asObservable();

  private get currentUser(): IdentUserI {
    return this.userService.me;
  }

  constructor(
    private readonly notificationsService: IrisNotificationsService,
    private readonly websocketService: WebsocketService,
    private readonly userService: IrisUserService,
    private readonly alertify: IrisAlertService,
    private readonly envService: IrisEnvironmentService,
  ) { }

  getUnlockRequestedNotifications(): Observable<unknown> {
    if (!this.unlockRequestedNotifications$) {
      this.unlockRequestedNotifications$ = this.websocketService.connect(`/user-broker/${ this.currentUser.id }/protocol/unlock-requested`).pipe(
        map(data => data.body),
        map(body => JSON.parse(body)),
      );
    }
    return this.unlockRequestedNotifications$;
  }

  setAsRead(notification: IrisNotificationI): void {
    this.notificationsService.setMessageAsRead(notification.hash).pipe(
      tap((updatedNotification) => {
        const listCurrent = this.currentUserNotificationsSubject.getValue();
        const indexCurrent = listCurrent.findIndex(value => value.id == notification.id);
        if (indexCurrent > -1) {
          listCurrent.splice(indexCurrent, 1);
        }
        this.currentUserNotificationsSubject.next(listCurrent);

        const allUserNotificationsValue = this.allUserNotificationsSubject.getValue();
        const indexAll = allUserNotificationsValue.notifications.findIndex(value => value.id == notification.id);
        if (indexAll > -1) {
          allUserNotificationsValue.notifications[indexAll].read = updatedNotification.read;
        }
        this.allUserNotificationsSubject.next(allUserNotificationsValue);
      }),
      takeUntil(this._destroyedSubject),
    ).subscribe();
  }

  setAllAsRead(): void {
    this.notificationsService.setAllAsRead().pipe(
      tap(() => {
        this.currentUserNotificationsSubject.next([]);
        const listAll = this.allUserNotificationsSubject.getValue();
        listAll.notifications.forEach(item => item.read = true);
        this.allUserNotificationsSubject.next(listAll);
      }),
      takeUntil(this._destroyedSubject),
    ).subscribe();
  }

  getAllNotifications(limit: number, offset: number): Observable<IrisNotificationI[]> {
    return this.notificationsService.getUserNotifications(this.userService.me.id, limit + 1, offset, false).pipe(
      tap((notifications) => {
        let moreAvailable: boolean;
        if (notifications.length > limit) {
          notifications.pop();
          moreAvailable = true;
        } else {
          moreAvailable = false;
        }
        const allNotificationsRes = {
          moreAvailable,
          notifications: this.allUserNotificationsSubject.getValue().notifications.concat(notifications),
        };
        this.allUserNotificationsSubject.next(allNotificationsRes);
      }),
    );
  }

  initStore(): void {
    this.notificationsService.getUserNotifications(this.currentUser.id, 25, null, true).pipe(
      tap((notifications) => {
        this.currentUserNotificationsSubject.next(notifications);
      }),
      takeUntil(this._destroyedSubject),
    ).subscribe();

    this.websocketService.watchNotificationsWs(`/instance-broker/${ this.envService.env.instanceId }/${ this.currentUser.id }/notification`).pipe(
      map(data => data.body),
      map(body => JSON.parse(body)),
      map(notif => notif ? notif.notification : null),
      tap((notification: IrisNotificationI) => {
        if (isNil(notification)) { return; }
        const alert = alertNotification(notification);
        this.alertify.alert(alert.type, alert.message, { dismissible: true, dismissTimeout: 30000 });

        notification.sentDate = Date.now().toString();
        this.currentUserNotificationsSubject.getValue().unshift(notification);
        this.currentUserNotificationsSubject.next(this.currentUserNotificationsSubject.getValue());

        this.allUserNotificationsSubject.getValue().notifications.unshift(notification);
        this.allUserNotificationsSubject.next(this.allUserNotificationsSubject.getValue());
      }),
      takeUntil(this._destroyedSubject),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
