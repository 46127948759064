import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { computed, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiUrl, IrisApiClient, IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { Observable, of, map, switchMap, tap } from 'rxjs';
import {
  IrisEmailMessageI,
  IrisEmailMessageAttachmentI,
  IrisEmailRecipientI,
  IrisEmailMessageResponseI,
  IrisEmailMessage,
  IrisEmailsContactI,
} from '../models/IrisEmail';
import { IrisEmailFolderResponseI } from '../models/IrisEmailNavigation';
import { IrisDMSFolderI } from '../../dms/models/IrisDMSFolder';
import { IrisEmailsAdvancedSearchFilterI, IrisEmailsPaginationI } from '../models/IrisEmailPagination';
import { IrisDMSFileI } from '../../dms/models/IrisDMSFile';
import { IrisTimeSandbox } from '../../time';
import { IrisMsalService } from '@iris/modules/msal/msal.service';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { isBoolean, isEmpty } from 'lodash';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import {
  IrisEmailIntegrationLogI,
  IrisEmailIntegrationLogUserActionType,
  IrisSharedMailboxCreationStatusType,
  IrisSharedMailboxI,
  IrisSharedMailboxUserOperation,
} from '../models/shared-mailbox';
import { IrisFilterPropertyMeta } from '@iris/modules/module-filters-v2/filter-meta';
import { filterValueCommonIsEmpty, stringifyDate } from '@iris/modules/module-filters-v2/utils';
import { IrisRange } from '@iris/modules/module-filters-v2/models';
import { dateRangeChipRenderingOptions, TEXT_CHIP_RENDERING_OPTIONS } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { AbstractDatetime } from '../../date';
import { IrisPage } from '@iris/common/models/page';

export interface IrisEmailMessageWrappedResponseI<T> {
  count: number;
  currentPage: T[];
  nextPage: string;
}

export interface IrisEmailsClientListResponseI<T> {
  odataCount: number;
  value: T[];
  odataNextLink?: string;
}

export interface IrisEmailsPage<T> extends IrisPage<T> {
  nextPage?: string;
}

export interface IrisEmailsHttpErrorResponse extends HttpErrorResponse {
  messages?: string[];
}

@Injectable({ providedIn: 'root' })
@ApiUrl('/mail-client/{instanceId}')
export class IrisEmailsService extends IrisApiClient<IrisEmailMessageI> {
  protected messages: IrisEmailMessageI[] = [];
  protected draftMessages: IrisEmailMessageI[] = [];
  protected attachments: IrisEmailMessageAttachmentI[] = [];

  public sharedMailbox = '';

  readonly emailsFilterMeta = computed(() => {
    const filterMeta: IrisFilterPropertyMeta[] = [
      {
        propertyId: 'subject',
        label: 'label.email.Subject',
        appendFilterQueryParamsFn: (builder: IrisQueryParamsBuilder, subject: string) => {
          if (!subject) {
            return builder;
          }
          return builder.urlParam('subject', subject);
        },
        chipRenderingOptions: TEXT_CHIP_RENDERING_OPTIONS,
        filterValueIsEmpty: filterValueCommonIsEmpty,
      },
      {
        propertyId: 'body',
        label: 'label.email.Keywords',
        appendFilterQueryParamsFn: (builder: IrisQueryParamsBuilder, body: string) => {
          if (!body) {
            return builder;
          }
          return builder.urlParam('body', body);
        },
        chipRenderingOptions: TEXT_CHIP_RENDERING_OPTIONS,
        filterValueIsEmpty: filterValueCommonIsEmpty,
      },
      {
        propertyId: 'period',
        label: 'label.CreatedBetween',
        appendFilterQueryParamsFn: (builder: IrisQueryParamsBuilder, val: IrisRange<AbstractDatetime>) => {
          if (!val?.from && !val?.to) { return builder; }
          const from = val.from || null;
          const to = val.to || null;

          if (from) {

            builder = builder.urlParam('fromDate', AbstractDatetime.parse(from).utc().startOf('day').toIsoString());
          }

          if (to) {
            builder = builder.urlParam('toDate', AbstractDatetime.parse(to).utc().endOf('day').toIsoString());
          }
          return builder;
        },
        chipRenderingOptions: dateRangeChipRenderingOptions(date => stringifyDate(date, this.timeSandbox, 'utc')),
        filterValueIsEmpty: filterValueCommonIsEmpty,
      },
      {
        propertyId: 'hasAttachments',
        label: 'label.HasAttachments',
        appendFilterQueryParamsFn: (builder: IrisQueryParamsBuilder, hasAttachments: boolean) => {
          if (!isBoolean(hasAttachments)) {
            return builder;
          }
          return builder.urlParam('hasAttachments', hasAttachments);
        },
        chipRenderingOptions: TEXT_CHIP_RENDERING_OPTIONS,
        filterValueIsEmpty: filterValueCommonIsEmpty,
      },
      {
        propertyId: 'unread',
        label: 'label.Unread',
        appendFilterQueryParamsFn: (builder: IrisQueryParamsBuilder, unread: boolean) => {
          if (!isBoolean(unread)) {
            return builder;
          }
          return builder.urlParam('unread', unread);
        },
        chipRenderingOptions: TEXT_CHIP_RENDERING_OPTIONS,
        filterValueIsEmpty: filterValueCommonIsEmpty,
      },
    ];

    return filterMeta;
  });

  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly msalService: IrisMsalService,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly userService: IrisUserService,
    private readonly envService: IrisEnvironmentService,
    private readonly timeSandbox: IrisTimeSandbox,
  ) {
    super(httpClient);

    this.handleProjectChange();
  }

  get userId(): number {
    return this.userService.me.id;
  }

  get userEmailAddress(): IrisEmailRecipientI {
    return {
      emailAddress: {
        address: this.userService.me.email,
        name: this.userService.me.fullName,
      },
    };
  }

  get instanceId(): string {
    return this.envService.env.instanceId;
  }

  @ApiUrl('~/users/{userId}')
  checkUserAccess(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    }));
  }

  @ApiUrl('~/users/{userId}/folders')
  getCustomFolders(limit = 50, offset = 0, includeHiddenFolders = true): Observable<IrisPage<IrisEmailFolderResponseI>> {
    const params = { offset, limit, includeHiddenFolders };
    return this.httpClient.get<IrisEmailMessageWrappedResponseI<IrisEmailFolderResponseI>>(this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    }), { params }).pipe(
      map(response => ({
        count: response.count,
        elements: response.currentPage,
      })),
    );
  }

  @ApiUrl('~/users/{userId}/folders/{folderId}')
  getCustomFolder(folderId: string): Observable<IrisEmailFolderResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId,
    });
    return this.httpClient.get<IrisEmailFolderResponseI>(url);
  }

  @ApiUrl('~/users/{userId}/folders/{parentFolderId}/child')
  getChildrenFolders(parentFolderId: string): Observable<IrisEmailsPage<IrisEmailFolderResponseI>> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      parentFolderId,
    });
    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailFolderResponseI>>(url).pipe(
      map(response => ({
        count: response.odataCount,
        elements: response.value,
        nextPage: response.odataNextLink,
      })),
    );
  }

  @ApiUrl('~/users/{userId}/folders/{folderId}/messages')
  getMessagesByAlias(
    alias: string,
    pagination: IrisEmailsPaginationI,
  ): Observable<IrisEmailsPage<IrisEmailMessageResponseI>> {
    const { offset, limit, searchText, filter, orderBy } = pagination;

    const extractedFilter = this.extractEmailsAdvancedSearchFilter(filter);
    const extraParams = new IrisQueryParamsBuilder(extractedFilter);

    extraParams.urlParam('offset', offset);
    if (limit) {
      extraParams.limit(limit);
    }
    if (!!orderBy?.length && !searchText && isEmpty(extractedFilter)) {
      extraParams.urlParam('order-by', orderBy);
    }
    if (searchText) {
      extraParams.urlParam('search', searchText);
    }
    const params = extraParams.toObject() as HttpParams;
    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailMessageResponseI>>(this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId: alias,
    }), { params }).pipe(
      map(response => ({
        count: response.odataCount,
        elements: response.value,
        nextPage: response.odataNextLink,
      })),
    );
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}')
  getMessageById(messageId: string, attachments = false): Observable<IrisEmailMessageI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });
    return this.httpClient.get<IrisEmailMessageResponseI>(url, { params: { attachments }  }).pipe(
      map(message => new IrisEmailMessage().fromResponse(message)),
    );
  }

  markMessageAsRead(messageId: string): Observable<IrisEmailMessageI> {
    return this.updateMessage(messageId, { isRead: true }).pipe(
      map(message => new IrisEmailMessage().fromResponse(message)),
    );
  }

  markMessageAsUnread(messageId: string):  Observable<IrisEmailMessageI> {
    return this.updateMessage(messageId, { isRead: false }).pipe(
      map(message => new IrisEmailMessage().fromResponse(message)),
    );
  }

  @ApiUrl('~/users/{userId}/bulk/mark-read/folder/{folderId}')
  setMessagesReadMarkBulk(folderId: string, read: boolean, includeIds: string[], excludeIds: string[]): Observable<void> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId,
    });

    const params = { read, includeIds, excludeIds };

    return this.httpClient.post<void>(url, null, { params });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments')
  getMessageAttachments(messageId: string): Observable<IrisEmailMessageAttachmentI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.get<IrisEmailMessageWrappedResponseI<IrisEmailMessageAttachmentI>>(url).pipe(
      map(response => response.currentPage),
    );
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments?jwt={jwt}')
  getUploadAttachmentUrl(messageId: string): Observable<string> {
    const url = this.fullUrl({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });
    return this.msalService.azureAccessToken$.pipe(
      map(jwt => url + jwt),
    );
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/list?jwt=')
  getUploadAttachmentsBulkUrl(messageId: string): Observable<string> {
    const url = this.fullUrl({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });
    return this.msalService.azureAccessToken$.pipe(
      map(jwt => url + jwt),
    );
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments')
  uploadAttachment(messageId: string, attachment: File): Observable<IrisEmailMessageAttachmentI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    const formData = new FormData();

    formData.append('attachment', attachment);

    return this.httpClient.post<IrisEmailMessageAttachmentI>(url, formData);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/list')
  uploadAttachmentsBulk(messageId: string, attachments: File[]): Observable<IrisEmailMessageAttachmentI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    const formData = new FormData();

    attachments.forEach(attachment => {
      formData.append('file', attachment);
    });

    return this.httpClient.post<IrisEmailMessageAttachmentI[]>(url, formData);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/dms-files/{fileId}')
  uploadAttachmentFromDms(messageId: string, fileId: string): Observable<IrisEmailMessageAttachmentI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      fileId,
    });

    return this.httpClient.post<IrisEmailMessageAttachmentI>(url, null);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/dms-file-list')
  uploadAttachmentFromDmsBulk(messageId: string, filesIds: string[]): Observable<IrisEmailMessageAttachmentI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.post<IrisEmailMessageAttachmentI[]>(url, filesIds);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/{attachmentId}')
  removeAttachment(messageId: string, attachmentId: string): Observable<string> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      attachmentId,
    });

    return this.httpClient.delete<string>(url);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/{attachmentId}/dms-folders/{folderId}')
  saveMessageAttachmentToDms(messageId: string, attachmentId: string, folderId: string): Observable<IrisDMSFileI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      attachmentId,
      folderId,
    });

    return this.httpClient.post<IrisDMSFileI>(url, null);
  }

  toggleMailViewFullWidthMode(isFullwidth: boolean): void {
    const module: HTMLElement = this.document.querySelector('iris-emails-main');
    const navigation: HTMLElement = this.document.querySelector('.mail-navigation-box');
    const list: HTMLElement = this.document.querySelector('.mail-messages-list-box');

    const sideWidth = navigation.offsetWidth + list.offsetWidth;
    navigation.style.marginLeft = !isFullwidth  ? '0px' : `-${sideWidth}px`;
    module.classList.toggle('fullscreen', isFullwidth);
  }

  @ApiUrl('~/users/{userId}/messages')
  saveMessageAsDraft(message: Partial<IrisEmailMessageResponseI>): Observable<IrisEmailMessageResponseI> {
    const messageToUpdate = message.id ? this.draftMessages.find(_message => _message.id === message.id) : null;
    const messageBody = {
      ...message,
      from: messageToUpdate?.from ?? this.userEmailAddress,
      isRead: true,
      isDraft: true,
    };

    if (message.id) {
      return this.updateMessage(message.id, messageBody);
    }

    const url = this.url({ instanceId: this.instanceId, userId: this.sharedMailbox });

    return this.httpClient.post<IrisEmailMessageResponseI>(url, messageBody);
  }

  @ApiUrl('~/users/{userId}/messages')
  sendDraftMessage(message: Partial<IrisEmailMessageResponseI>): Observable<IrisEmailMessageI> {
    let url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    });

    const body = {
      isDraft: false,
    };

    return this.saveMessageAsDraft(message).pipe(
      switchMap((savedMessage) => {
        url += `/${savedMessage.id}`;
        return this.httpClient.post<IrisEmailMessageI>(url, body);
      }),
    );
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/forward')
  forwardMessage(
    messageId: string,
    message: Partial<IrisEmailMessageResponseI>,
  ): Observable<IrisEmailMessageResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.post<IrisEmailMessageResponseI>(url, message);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/reply')
  replyToMessage(
    messageId: string,
    message: Partial<IrisEmailMessageResponseI>,
  ): Observable<IrisEmailMessageResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.post<IrisEmailMessageResponseI>(url, message);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/reply-all')
  replyToAll(
    messageId: string,
    message: Partial<IrisEmailMessageResponseI>,
  ): Observable<IrisEmailMessageResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.post<IrisEmailMessageResponseI>(url, message);
  }

  @ApiUrl('~/users/{userId}/messages/conversation/{conversationId}')
  getMessageConversation(conversationId: string): Observable<IrisEmailMessageResponseI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      conversationId,
    });

    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailMessageResponseI>>(url).pipe(
      map(response => response.value),
    );
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}')
  updateMessage(messageId: string, body: Partial<IrisEmailMessageI | IrisEmailMessageResponseI>): Observable<IrisEmailMessageResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.put<IrisEmailMessageResponseI>(url, body);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}')
  removeMessage(messageId: string): Observable<unknown> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });
    return this.httpClient.delete(url);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/folders/{folderId}/move')
  moveMessageToFolder(messageId: string, folderId: string): Observable<IrisEmailMessageI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      folderId,
    });
    return this.httpClient.post<IrisEmailMessageI>(url, null);
  }

  @ApiUrl('~/users/{userId}/bulk/folder/{folderId}')
  removeMessagesBulk(folderId: string, includeIds: string[], excludeIds: string[]): Observable<void> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId,
    });
    const params = { includeIds, excludeIds };
    return this.httpClient.delete<void>(url, { params });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/dms-folders/{folderId}')
  saveMessageToDms(messageId: string, folderId: string): Observable<IrisDMSFolderI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      folderId,
    });
    return this.httpClient.post<IrisDMSFolderI>(url, null);
  }

  @ApiUrl('~/users/{userId}/bulk/export/folder/{folderId}/dms-folder/{dmsFolderId}')
  saveMessageToDmsBulk(
    folderId: string,
    dmsFolderId: string,
    includeIds: string[],
    excludeIds: string[],
  ): Observable<IrisDMSFolderI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId,
      dmsFolderId,
    });
    const body = {
      includeIds,
      excludeIds,
    };
    return this.httpClient.post<IrisDMSFolderI>(url, body);
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/dms-folders/{folderId}')
  saveMessagesAttachmentsToDms(messageId: string, folderId: string): Observable<IrisDMSFolderI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      folderId,
    });
    return this.httpClient.post<IrisDMSFolderI>(url, null);
  }

  @ApiUrl('~/users/{userId}/contacts')
  getContacts(searchParams: IrisQueryParams): Observable<IrisPage<IrisEmailsContactI>> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    });
    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailsContactI>>(
      url,
      { params: searchParams.toObject() },
    ).pipe(
      map(response => ({
        count: response.odataCount,
        elements: response.value,
      })),
    );
  }

  @ApiUrl('~/users/{userId}/contacts/{contactId}')
  getContactById(contactId: string, queryParams: IrisQueryParams): Observable<IrisEmailsContactI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      contactId,
    });
    return this.httpClient.get<IrisEmailsContactI>(url, { params: queryParams.toObject() });
  }

  @ApiUrl('~/users/{userId}/contacts')
  createContact(contact: Partial<IrisEmailsContactI>): Observable<IrisEmailsContactI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    });
    return this.httpClient.post<IrisEmailsContactI>(url, contact);
  }

  @ApiUrl('~/users/{userId}/contacts/{contactId}')
  deleteContact(contactId: string): Observable<unknown> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      contactId,
    });
    return this.httpClient.delete<unknown>(url);
  }

  @ApiUrl(`~/users/{userId}/folders/unread-emails`)
  getUnreadCount(): Observable<number> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    });
    return this.httpClient.get<{ count: number }>(url).pipe(
      map(response => response.count),
    );
  }

  @ApiUrl(`~/shared-mailbox/projects/{projectId}/status`)
  getSharedMailboxCreationStatus(projectId: number): Observable<IrisSharedMailboxCreationStatusType> {
    const url = this.url({
      instanceId: this.instanceId,
      projectId,
    });

    return this.httpClient.get<{ projectId: number; smbStatus: IrisSharedMailboxCreationStatusType }>(url).pipe(map(response => response.smbStatus));
  }

  @ApiUrl('~/shared-mailbox')
  createSharedMailBox(sharedMailbox: Partial<IrisSharedMailboxI>): Observable<unknown> {
    const url = this.url({
      instanceId: this.instanceId,
    });

    return this.httpClient.post<unknown>(url, sharedMailbox);
  }
  
  @ApiUrl('~/shared-mailbox/{sharedMailbox}/status')
  retryMailboxCreation(sharedMailbox: string): Observable<unknown> {
    const url = this.url({
      instanceId: this.instanceId,
      sharedMailbox,
    });

    return this.httpClient.post<unknown>(url, null);
  }

  @ApiUrl('~/shared-mailbox/projects/{projectId}')
  getIntegationLogs(projectId: number): Observable<IrisEmailIntegrationLogI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      projectId,
    });

    return this.httpClient.get<IrisEmailIntegrationLogI[]>(url);
  }

  @ApiUrl('~/shared-mailbox/users')
  addUsersToSmb(userIds: number[], projectId: number): Observable<unknown> {
    if (!this.sharedMailbox) { return of(null); }
    const url = this.url({
      instanceId: this.instanceId,
    });

    return this.httpClient.post<unknown>(
      url,
      { userIds, projectId, userOperation: IrisSharedMailboxUserOperation.Add },
    );
  }

  @ApiUrl('~/shared-mailbox/users')
  removeUsersFromSmb(userIds: number[], projectId: number): Observable<unknown> {
    if (!this.sharedMailbox) { return of(null); }
    const url = this.url({
      instanceId: this.instanceId,
    });

    return this.httpClient.post<unknown>(
      url,
      { userIds, projectId, userOperation: IrisSharedMailboxUserOperation.Delete },
    );
  }

  @ApiUrl(`~/shared-mailbox/{sharedMailbox}/users/{userEmail}/status`)
  retryUserActionForSmb(userEmail: string, sharedMailbox: string, action: IrisEmailIntegrationLogUserActionType): Observable<unknown> {
    const url = this.url({
      instanceId: this.instanceId,
      sharedMailbox,
      userEmail,
    });

    return this.httpClient.post<unknown>(url, null, { params: { action } });
  }

  private handleProjectChange(): void {
    this.globalSandbox.currentProject$.pipe(
      tap(project => {
        this.sharedMailbox = project?.sharedMailbox;
      }),
    ).subscribe();
  }

  private extractEmailsAdvancedSearchFilter(filter: IrisEmailsAdvancedSearchFilterI): IrisQueryParams {
    const filterKeys = Object.keys(filter ?? {});

    const queryBuilder = new IrisQueryParamsBuilder();

    this.emailsFilterMeta()
      .filter(property => filterKeys.includes(property.propertyId))
      .forEach(property => property.appendFilterQueryParamsFn(queryBuilder, filter[property.propertyId]));

    return queryBuilder.toStructure();
  }
}
