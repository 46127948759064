import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { mergeMap, Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { IrisMsalService } from '@iris/modules/msal/msal.service';
import { IrisUserService } from '@iris/common/services/user.service';

const EMAIL_CLIENT_URL_PART = '/mail-client/';
const ONE_DRIVE_CONNECTOR_URL_PART = '/one-drive-connector/';

const REQUIRED_AZURE_TOKEN = [
  EMAIL_CLIENT_URL_PART,
  ONE_DRIVE_CONNECTOR_URL_PART,
];

@Injectable()
export class IrisAzureAuthInterceptor implements HttpInterceptor {
  private readonly msalService = inject(IrisMsalService);
  private readonly userService = inject(IrisUserService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const tokenRequired = REQUIRED_AZURE_TOKEN.some(urlPart => req.url.includes(urlPart));
    if (!tokenRequired || !this.userService.me.ssoActive) {
      return next.handle(req);
    }
    return this.msalService.azureAccessToken$.pipe(
      mergeMap(accessToken => next.handle(req.clone({ headers: req.headers.set('x-iris-azure-token', accessToken) }))),
    );
  }
}
