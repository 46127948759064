import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FileUploadModule } from 'ng2-file-upload';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { StrakitProgressBarComponent } from '@ngx-strakit/cdk/controls/progress-bar';

@NgModule({
  imports: [
    MatTooltipModule,
    TranslateModule,
    EditorModule,
    FileUploadModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NgxTippyModule,
    InfiniteScrollModule,
    MatButtonToggleModule,
    MatStepperModule,
    StrakitProgressBarComponent,
  ],
  exports: [
    MatTooltipModule,
    TranslateModule,
    EditorModule,
    FileUploadModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NgxTippyModule,
    InfiniteScrollModule,
    MatButtonToggleModule,
    MatStepperModule,
    StrakitProgressBarComponent,
  ],
})
export class IrisExternalCommonLibsModule {
}
