// For a guide how to use feature flag please refer to https://confluence.strabag.io/display/4D/Gitlab+feature+flags
export enum IrisFeatureFlags {
  // Whether form block is hidden in the configuration of any template IRIS-34542
  FORMS_FULL_CONFIGURATION = 'forms_full_configuration',

  // Whether during creation of new project manually the Organizationa Unit fields (UB, BE, DI and GR) have to be mandatory, IRIS-35119
  ORG_UNIT_MANDATORY = 'org_unit_mandatory',

  // Whether using strasync or stratek endpoints for project synchronization IRIS-34542
  STRASYNC = 'strasync',

  // Whether central project data page should be accessible
  CENTRAL_PROJECT_DATA = 'central_project_data',
}
