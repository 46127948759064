import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, retry, throwError } from 'rxjs';
import { MicroservicesUtils } from '@iris/common/utils/url.utils';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { concatMap } from 'rxjs/operators';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { LoggerService } from '@iris/common/modules/rum/logger.service';
import { NO_AUTH_TOKEN_PATTERNS } from './no-auth-token-patterns';

const TOKEN_REFRESH_RETRY_COUNT = 2;

const NO_REFRESH_TOKENS_PATTERN = [
  /security\/users\/logout/i,
];

@Injectable()
export class IrisAuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly envService: IrisEnvironmentService,
    private readonly authFacade: AuthFacade,
    private readonly loggerService: LoggerService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      (req.url.includes(this.envService.get('apiUrl')) || MicroservicesUtils.isPrivateUrl(req.url)) &&
      !req.headers.get('Authorization') &&
      !NO_AUTH_TOKEN_PATTERNS.some((x) => req.url.match(x))
    ) {
      return this.authFacade.actualToken().pipe(
        concatMap((accessToken) => {
          if (!accessToken) {
            return next.handle(req);
          }

          return next.handle(req.clone({ headers: req.headers.set('Authorization', `Bearer ${accessToken}`) })).pipe(
            retry({
              count: TOKEN_REFRESH_RETRY_COUNT,
              delay: (error: HttpErrorResponse, retryCount: number) => {
                // handle only 401 error
                if ((error.status as HttpStatusCode) === HttpStatusCode.Unauthorized) {
                  if (!NO_REFRESH_TOKENS_PATTERN.some((x) => req.url.match(x)) && retryCount < TOKEN_REFRESH_RETRY_COUNT) {
                    return this.authFacade.actualToken(true);
                  }
                  const { accessToken, refreshToken } = this.authFacade.getTokensData();
                  const message = `Message: Refresh token failed (auth-interceptor) | RefreshToken: ${refreshToken} | AccessToken: ${accessToken}`;
                  this.loggerService.logMessageToKibana(message, 'Authorization error').then(() => this.authFacade.logout());
                }
                return throwError(() => error);
              },
            }),
          );
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}
