import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { EmailWellKnownFolderName, IrisEmailNavigation, IrisEmailNavigationI } from '../../models/IrisEmailNavigation';
import { removeEntities, setAllEntities, setEntities, setEntity, withEntities } from '@ngrx/signals/entities';
import { setError, setLoaded, setLoading, withRequestState } from '@iris/common/signals/features/request-status.feature';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { computed, inject } from '@angular/core';
import { IrisEmailsHttpErrorResponse, IrisEmailsService } from '../../services/emails.service';
import { map, mergeMap, pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { chain } from 'lodash';

export const FOLDERS_ORDER = {
  [EmailWellKnownFolderName.Inbox]: 0,
  [EmailWellKnownFolderName.Drafts]: 1,
  [EmailWellKnownFolderName.SentItems]: 2,
  [EmailWellKnownFolderName.DeletedItems]: 3,
  [EmailWellKnownFolderName.JunkEmail]: 4,
  [EmailWellKnownFolderName.Archive]: 5,
  [EmailWellKnownFolderName.Outbox]: 6,
};

export interface IrisEmailsNavigationState {
  selectedFolderId: string;
  rootFolderId: string;
  folderShortcutMap: Map<EmailWellKnownFolderName, string>;
}

export const initialState: IrisEmailsNavigationState = {
  selectedFolderId: null,
  rootFolderId: null,
  folderShortcutMap: new Map(),
};

export const IrisEmailsNavigationStore = signalStore(
  { providedIn: 'root' },
  withEntities<IrisEmailNavigationI>(),
  withState<IrisEmailsNavigationState>(initialState),
  withRequestState(),
  withComputed(store => ({
    total: computed(() => store.entities.length),
  })),
  withMethods(store => ({
    getChildren: (parentFolderId: string) => computed(() => getFoldersByParentId(store.entities(), parentFolderId)),
    getFolderById: (folderId: string) => computed(() => store.entityMap()[folderId]),
    getFolderByTitle: (title: string) => computed(() => store.entities().find(folder => folder.title.toLowerCase() === title.toLowerCase())),
    getFolderByShortcut: (folderShortcut: EmailWellKnownFolderName) => computed(() => {
      return store.entities().find(folder => folder.wellKnownFolderName === folderShortcut);
    }),
    setSelectedFolderId: (selectedFolderId: string) => patchState(store, { selectedFolderId }),
  })),
  withMethods((
    store,
    emailsService = inject(IrisEmailsService),
    alert = inject(IrisAlertService),
  ) => ({
    fetchCustomFolders$: rxMethod<void>(
      pipe(
        switchMap(() => {
          patchState(store, setLoading());

          return emailsService.getCustomFolders().pipe(
            tapResponse({
              next: ({ elements }) => {
                const foldersToAdd = (elements || []).map(folder => new IrisEmailNavigation(folder));
                patchState(store, setAllEntities(foldersToAdd), setLoaded());
              },
              error: (err: IrisEmailsHttpErrorResponse) => {
                const errorMessage = err?.messages?.[0];
                if (errorMessage) {
                  alert.error(errorMessage);
                }
                patchState(store, setError(errorMessage || err.message));
              },
            }),
          );
        }),
      ),
    ),
    fetchCustomFolder$: rxMethod<string>(
      pipe(
        mergeMap(folderId => emailsService.getCustomFolder(folderId).pipe(
          map(folder => patchState(store, setEntity(new IrisEmailNavigation(folder)))),
        )),
      ),
    ),
    fetchChildrenFolders$: rxMethod<string>(
      pipe(
        mergeMap(parentFolderId => emailsService.getChildrenFolders(parentFolderId).pipe(
          map(({ elements }) => patchState(store, setEntities((elements || []).map(folder => new IrisEmailNavigation(folder))))),
        )),
      ),
    ),
    collapseFolder: (folderId: string) => {
      const parentFolder = store.entityMap()[folderId];
      const childrenIds = getFlatFoldersTree(store.entities(), parentFolder).map(child => child.id);
      patchState(store, removeEntities(childrenIds));
    },
    getFolderIdByShortcut: (folderShortcut: EmailWellKnownFolderName) => computed(() => store.getFolderByShortcut(folderShortcut)()?.id),
    sortedVisibleFlatNavigationTree: computed(() => {
      const entities = store.entities();
      if (!entities.length) {
        return [];
      }
      const inboxFolder = store.getFolderByShortcut(EmailWellKnownFolderName.Inbox)();
      const rootFolders = getFoldersByParentId(entities, inboxFolder.parentFolderId);
      return chain(rootFolders)
        .sortBy(folder => FOLDERS_ORDER[folder.wellKnownFolderName])
        .map(folder => [new IrisEmailNavigation(folder), ...getFlatFoldersTree(entities, folder)])
        .flatten()
        .value();
    }),
  })),
);

function getFoldersByParentId(folders: IrisEmailNavigationI[], parentId: string): IrisEmailNavigationI[] {
  return folders.filter(folder => folder.parentFolderId === parentId);
}

function getFlatFoldersTree(folders: IrisEmailNavigationI[], parentFolder: IrisEmailNavigationI): IrisEmailNavigationI[] {
  if (!parentFolder.childFolderCount) { return []; }
  const children = getFoldersByParentId(folders, parentFolder.id);
  return chain(children).map(child => [new IrisEmailNavigation(child), ...getFlatFoldersTree(folders, child)]).flatten().value();
};
